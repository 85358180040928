<template>
  <va-dropdown
    class="language-dropdown"
    v-model="isShown"
    offset="0, 16px"
    fixed
  >
    <span
      slot="anchor"
      :style="{color: color}"
    >
      <h6>
        {{ flagName(currentLanguage) }}
        <va-icon
          class="pa-1"
          :name="`fa ${isShown ? 'fa-angle-up' :'fa-angle-down'}`"
        />
      </h6>
    </span>
    <div class="language-dropdown__content pl-4 pr-4 pt-2 pb-2">
      <div
        class="language-dropdown__item row align--center pt-1 pb-1 mt-2 mb-2"
        v-for="(option, id) in options"
        :key="id"
        :class="{ active: option.alpha_code === currentLanguage }"
        @click="setLanguage(option.alpha_code)"
      >
        <span class="dropdown-item__text">
          {{ $t(`language.${option.name}`) }}
        </span>
      </div>
    </div>
  </va-dropdown>
</template>

<script>
import { loadLanguageAsync } from '@/i18n'

export default {
  name: 'language-dropdown',
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
  data () {
    return {
      isShown: false,
      options: [],
      retry: 0,
    }
  },
  computed: {
    currentLanguage () {
      return this.$root.$i18n.locale
    },
  },
  created () {
    if (this.options.length === 0) {
      this.getRemoteLangs()
    }
  },
  methods: {
    async getRemoteLangs () {
      let response = false
      try {
        response = await this.$http.get('/langs/list?type=0&skipData=true')
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        setTimeout(() => {
          if (this.retry < 4) {
            this.retry++
            this.getRemoteLangs()
          }
        })
        return
      }

      this.options = response.data.data
    },
    async setLanguage (locale) {
      await loadLanguageAsync(locale)
      this.$store.commit('updateLocale', locale)

      this.$http.defaults.headers.common['Accept-Language'] = locale
    },
    flagName (locale) {
      return locale.toUpperCase()
    },
    flagIconClass (code) {
      return `flag-icon-${code}`
    },
  },
}
</script>

<style lang="scss">
.language-dropdown {
  cursor: pointer;

  &__content {
    background-color: $dropdown-background;
    box-shadow: $gray-box-shadow;
    border-radius: 0.5rem;
    width: 8rem;

    .flag-icon-small {
      min-width: 1.5rem;
      min-height: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  &__item {
    padding-bottom: 0.625rem;
    cursor: pointer;
    flex-wrap: nowrap;

    &:last-of-type {
      padding-bottom: 0 !important;
    }

    &:hover,
    &.active {
      color: var(--brand-primary);
      font-weight: bold;
    }
  }

  .flag-icon {
    border-radius: 5px;

    &::before {
      content: "";
    }
  }

  .flag-icon-large {
    display: block;
    width: 31px;
    height: 23px;
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
